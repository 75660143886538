import React from 'react';
import FormLoginAdminComponent from "../Components/Restaurante/Admin/LoginAdminComponent.tsx";

function Login() {
  return (
    <div>
        <FormLoginAdminComponent/>
    </div>
  );
}

export default Login;